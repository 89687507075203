<template>
  <div class="main-page">
    <div class="container">
      <div class="d-md-none">
        <app-bar
          title="Lupa Kata Sandi"
          :previousLink="{ name: 'Login' }"/>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6 d-flex align-items-center vh-md-100 px-3">
          <div class="card forgot-password p-0 p-md-5 border-0">
            <div class="card-body p-0">
              <div class="group-title mb-4">
                  <span class="title-content-package">Lupa Kata Sandi</span> <br>
                  <span class="fw-normal fs-12 color-neutral-600">Silahkan masukkan alamat Email / No Telepon anda, kami akan mengirimkan tautan untuk mengatur ulang.</span>
              </div>
              <Form class="px-md-5" @submit="resetPassword">
                  <label>Alamat Email / No Telepon</label>
                  <div class="input-group mb-3">
                    <Field
                      type="text"
                      v-model="email"
                      name="email"
                      class="form-control"
                      placeholder="Cth: nama@email.com / 628383xxxxxxx" />
                      <span class="invalid-feedback">{{ errorMessage }}</span>
                      <ErrorMessage name="email" class="invalid-feedback" />
                  </div>
                  <button
                    class="btn btn-primary w-100 btn-login p-md-3"
                    type="submit"
                    :disabled="loading || !email"
                  >
                    {{ (loading) ? 'Tunggu..':'Kirim' }}
                  </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axios'
import { Field, Form, ErrorMessage } from 'vee-validate'
import AppBar from '@/components/AppBar.vue'
import { title } from '@/core/page'

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    AppBar
  },
  setup () {
    onMounted(() => {
      nextTick(() => {
        title.value = 'Lupa Password'
      })
    })

    const email = ref('')
    const errorMessage = ref('')
    const router = useRouter()
    const loading = ref(false)

    const resetPassword = async () => {
      try {
        loading.value = true
        const reset = await axios.post('app/lupa_password', {
          email: email.value
        })

        if (reset.data.success === true) {
          router.push({ name: 'SettingPasswordOtp', params: { email: email.value } })
        }
      } catch (error) {
        errorMessage.value = error.response.data.message
      } finally {
        loading.value = false
      }
    }

    return {
      resetPassword,
      email,
      errorMessage,
      loading
    }
  }
}
</script>

<style lang="scss" scoped>

.card.forgot-password {
  border-radius: 10px;

  @media (min-width: 520px) {
    box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);
  }
}

.form-control.is-invalid, .input-group-text.is-invalid {
    box-shadow: inset 0px -1px 0px #E21F39;
}

.form-control::placeholder {
    color: #CBD5E0;
}

.form-control, .input-group-text {
    background: none;
    border-radius: 0;
    box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
    padding: 8px 4px 8px 0px;
    font-size: 18px;
}

.invalid-feedback {
  display: block;
}

.btn-login {
  border: none;
  padding: 10px 20px;
}

</style>
